// iOS
var iOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;

$(function() {

    /***************************
    Header Loaded
    ****************************/
    setTimeout(function(){
        $('.header').addClass('header--loaded');
    }, 1000);

    

    /***************************
    Header Smaller
    ****************************/
    smallerHeader();

    /***************************
    Mobile who we are
    ****************************/
    whoWeAreMobile()
    
    /***************************
    Home Hero
    ****************************/
    $('#homeLearnMore.hero__link--scrollDown').click(function(e){
        e.preventDefault();
        $('body').animate({
            scrollTop: $('.stat').offset().top
        }, 400)
    });

    /***************************
    Linktree
    ****************************/
    if($('body').hasClass('page-template-template-linktree')) {
        setTimeout(function(){
            $('.linktree__background').addClass('linktree__background--loaded');
            $('.linktree').addClass('linktree--loaded');
        }, 500);
    }

    /***************************
    Scrll to contact section
    ****************************/
    $('#menu-item-717 a, a[href="#contactSection"], a[href="#contactsection"], .menu-item-717 a').click(function(e){
        e.preventDefault();

        if($(this).closest('nav').hasClass('navigation--mobile')){
            $('body').removeClass('navigation--mobile--open');
            $('.mobileNavigationTrigger i').attr('class', 'fa fa-navicon');
            $('html').css('overflow', '');
        }

        $('html, body').animate({
            scrollTop: $('#contactUs').offset().top - $('.header').height()
        }, 400);


    });

    /***************************
    Logo Carousel
    ****************************/
    if($('body').hasClass('home') || $('body').hasClass('page-template-template-new-homepage-php')){
        var $c = $('#carousel'),
            $w = $(window);
     
        $c.carouFredSel({
            align: false,
            items: {
                minimum: 0,
            },      
            scroll: {
                items: 1,
                duration: 3000,
                timeoutDuration: 0,
                easing: 'linear',
                pauseOnHover: 'immediate'
            }
        }); 
    
        $w.bind('resize.example', function() {
            var nw = $w.width();
            if (nw < 990) {
                nw = 990;
            }
     
            $c.width(nw * 3);
            $c.parent().width(nw);
     
        }).trigger('resize.example');
    }


    /***************************
    Slick Carousel for Page Builder
    ****************************/
    if($('.carousel__images').length) {
        $('.carousel__images').each(function(){
            $(this).slick({
                infinite: false,
                arrows: false,
                dots: true,
                slidesToShow: 3,
                slidesToScroll: 1,
                responsive: [
                    {
                      breakpoint: 768,
                      settings: {
                        slidesToShow: 2,
                      }
                    },
                ]
            });
        })
    }

    /***************************
    Mobile Navigation
    ****************************/
    $(window).on('resize', function(){
        mobileStatus();
    })
    
    $('.mobileNavigationTrigger').on('click',function(){
        $('body').toggleClass('navigation--mobile--open');

        if($('body').hasClass('navigation--mobile--open')){
            $('.mobileNavigationTrigger i').attr('class', 'fa fa-close');
            $('html').css('overflow', 'hidden');
        } else {
            $('.mobileNavigationTrigger i').attr('class', 'fa fa-navicon');
            $('html').css('overflow', 'auto');
        }
    });

});


function mobileStatus() {
    if($(window).width() > 980) {
        $('body').removeClass('navigation--mobile--open');
        $('.mobileNavigationTrigger i').attr('class', 'fa fa-navicon');
    }
}

// On Scroll
$(window).on('scroll', function(){
    smallerHeader();
});

// On Resize
$(window).on('resize', function(){
    whoWeAreMobile();
})

function smallerHeader(){
    var heroHeight = $('.hero').height(),
        minusHeight = heroHeight - 200;
    
    if($('body').hasClass('home') || $('body').hasClass('page-template-template-new-homepage-php')){
        minusHeight = 72;
    }
    
    if($(window).scrollTop() > minusHeight){
        $('.header').addClass('header--smaller');
    } else {
        $('.header').removeClass('header--smaller');
    }
}

// Who we are mobile
function whoWeAreMobile() {
    if($(window).width() <= 800){

        if(iOS){
            $('.whoWeAre__owner').on('vclick', function(){
                $(this).toggleClass('activated');
            });
        } else {
            $('.whoWeAre__owner').on('click touchstart', function(){
                if($(this).hasClass('activated')){
                    $(this).removeClass('activated');
                } else {
                    $(this).addClass('activated');
                }
            });
        }
    } else {
        $('.whoWeAre__owner').removeClass('activated');
    }
}

var map;
function initMap() {
    var locations = [
        ['Ontario', 51.2538, -85.3232, 2],
        ['Atlanta', 33.7490, -84.3880, 3],
        ['Marquette', 46.5476, -87.3956, 4],
        ['Madison', 43.0731, -89.4012, 5],
        ['New York City', 40.7128, -74.0060, 6],
        ['Philadelphia', 39.9526, -75.1652, 7],
        ['Arlington', 38.8800, -77.1068, 8],
        ['Washington State', 47.7511, -120.7401, 10],
        ['Chicago', 41.8781, 87.6298, 11],
    ];

    var team = [
        ['London', 51.5074, -0.1278, 1],
        ['Virginia Beach', 36.8529, -75.9780, 12],
        ['San Diego', 32.7157, -117.1611, 14],
        ['Washington, D.C.', 38.9072, -77.0369, 15],
        ['Norfolk', 36.8508, -76.2859, 16],
        ['Richmond', 37.5407, -77.4360, 17],
        ['Sandpoint', 48.2766, -116.5535, 18],
        
    ];

    var icon = {
        url: themePath + '/images/icon--mapMarker.png',
        scaledSize: new google.maps.Size(15, 15), // scaled size
        origin: new google.maps.Point(0,0), // origin
        anchor: new google.maps.Point(0, 0) // anchor
    };

    var teamIcon = {
        url: themePath + '/images/icon--m.png',
        scaledSize: new google.maps.Size(25, 25), // scaled size
        origin: new google.maps.Point(0,0), // origin
        anchor: new google.maps.Point(0, 0) // anchor
    };

    if(jQuery(window).width() < 621){
        mapCenter = new google.maps.LatLng(34.7917474, -97.9415449);
    } else if(jQuery(window).width() < 1551){
        mapCenter = new google.maps.LatLng(34.7917474, -97.9415449);
    } else {
        mapCenter = new google.maps.LatLng(34.7917474, -97.9415449);
    }

    if(jQuery(window).width() < 621){
        zoom = 3;
    } else if(jQuery(window).width() < 1170){
        zoom = 4;
    } else {
        zoom = 4;
    }

    var map = new google.maps.Map(document.getElementById('map'), {
      zoom: zoom,
      center: mapCenter,
      mapTypeId: google.maps.MapTypeId.ROADMAP,
    });

    var infowindow = new google.maps.InfoWindow();

    var marker, i;

    for (i = 0; i < locations.length; i++) {  
      marker = new google.maps.Marker({
        position: new google.maps.LatLng(locations[i][1], locations[i][2]),
        map: map,
        icon: icon
      });

      google.maps.event.addListener(marker, 'click', (function(marker, i) {
        return function() {
          infowindow.setContent(locations[i][0]);
          infowindow.open(map, marker);
        }
      })(marker, i));
    }


    for (i = 0; i < team.length; i++) {  
      marker = new google.maps.Marker({
        position: new google.maps.LatLng(team[i][1], team[i][2]),
        map: map,
        icon: teamIcon
      });

      google.maps.event.addListener(marker, 'click', (function(marker, i) {
        return function() {
          infowindow.setContent(team[i][0]);
          infowindow.open(map, marker);
        }
      })(marker, i));
    }

    var center;
    function calculateCenter() {
        if(jQuery(window).width() < 621){
            mapCenter = new google.maps.LatLng(34.7917474, -97.9415449);
        } else if(jQuery(window).width() < 1551){
            mapCenter = new google.maps.LatLng(34.7917474, -97.9415449);
        } else {
            mapCenter = new google.maps.LatLng(34.7917474, -97.9415449);
        }
    }

    function setZoom() {
        if(jQuery(window).width() < 621){
            zoom = 3;

        } else if(jQuery(window).width() < 1170){
            zoom = 4;
        } else {
            zoom = 4;
        }
    }
    
    google.maps.event.addDomListener(map, 'idle', function() {
      calculateCenter();
    });
    google.maps.event.addDomListener(window, 'resize', function() {
      map.setCenter(center);
      map.setZoom(zoom);
    });
}